import * as React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import FoundForm from './foundform';
import OwnerForm from './ownerform';
import ErrorPage from "./errorpage";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const router = createBrowserRouter([
  {
    path: "/c/:code",
    element: <ThemeProvider theme={theme}>
    {}
    <CssBaseline />
    <FoundForm />
  </ThemeProvider>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/own/:code",
    element: <ThemeProvider theme={theme}>
    {}
    <CssBaseline />
    <OwnerForm />
  </ThemeProvider>,
    errorElement: <ErrorPage />,
  },
  { path: "/",
    element: <div> Hello! Register a new!</div>,
    errorElement: <ErrorPage />,
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);



